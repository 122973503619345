import { render, staticRenderFns } from "./plHeader.vue?vue&type=template&id=6db6e501&scoped=true"
import script from "./plHeader.vue?vue&type=script&lang=js"
export * from "./plHeader.vue?vue&type=script&lang=js"
import style0 from "./plHeader.vue?vue&type=style&index=0&id=6db6e501&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db6e501",
  null
  
)

export default component.exports