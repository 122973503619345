<template>

      <div class="plHeaderDiv">
            <a href="https://www.personnel-consulting.co.uk" target="_blank">
                <v-img class="plHeaderLogo"
                       contain
                       max-width="350"
                       :src="require('@/assets/images/pcg-logo.svg')">
                </v-img>
            </a>
      </div>



</template>

<script>
       
    import { store }      from '@/store/store'

    export default {
      name    : 'plHeader',
      data () {
        return {  
            store,
        }
      },
      computed    : {},
      methods     : {}

    }

</script>

<style scoped>

 .plHeaderDiv{
     background: linear-gradient(white,white);
 }

 .plHeaderLogo {
    display     : flex;
    margin-right: auto;
    margin-left : auto;
    position    : relative;
    width       : 35%;
    padding     : 10px
}

 
</style>